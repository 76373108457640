import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import { fileUpload } from "../helpers/fileUpload";
import { types } from "../types/types";
import { openFormCrearEstudiante } from "./admin";
import { activarApoderado } from "./apoderado";
import { activarEstudiante } from "./estudiante";



export const crearInasistencia = ( inasistencia ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken('crearInasistencia', inasistencia, 'POST');
            const body = await resp.json();
            console.log(body)
            if(body.code ==='MNS.RMS.0200'){
                dispatch(crearInasistenciaOk(body.result.payload));
                Swal.fire({
                    icon: 'success',
                    title: 'Inasistencia registrada',
                    showConfirmButton: false,
                    timer: 1500
                })
                dispatch(openFormCrearEstudiante())
                dispatch(activarEstudiante({}))
                dispatch(activarApoderado({}))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: `${body.mensaje}`,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        } catch (error) {
            console.log(error);
            dispatch(crearInasistenciaError());
        }
    }
}

const crearInasistenciaOk = (inasistencia) => ({
    type: types.crearInasistenciaOk,
    payload: inasistencia
});

const crearInasistenciaError = () => ({ type: types.crearInasistenciaError });

export const obtenerInasistencias = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('obtenerInasistencias');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const sortedArray = body.result.payload.reverse()
                dispatch(obtenerInasistenciasOk(sortedArray));
            } else {
                dispatch(obtenerInasistenciasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerInasistenciasError());
        }
    }
}

const obtenerInasistenciasOk = (inasistencias) => ({ 
    type: types.obtenerInasistenciasOk ,
    payload: inasistencias
});

const obtenerInasistenciasError = () => ({ type: types.obtenerInasistenciasError })

export const actualizarInasistencia = (inasistencia) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`actualizarInasistencia/${inasistencia._id}`, inasistencia, 'PUT');
            const body = await resp.json();
            if(body.ok){
                dispatch(actualizarInasistenciaOk(body.inasistencia));
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarInasistenciaError())
        }
    }
}

const actualizarInasistenciaOk = (inasistencia) => ({ 
    type: types.actualizarInasistenciaOk,
    payload: inasistencia
});

const actualizarInasistenciaError = () => ({ type : types.actualizarInasistenciaError });


export const startUploading = (file) => {
    return async ( dispatch ) => {
        const fileUrl = await fileUpload(file);
        dispatch(startUploadingOk(fileUrl));
    }
}

export const startUploadingOk = (file) => ({
    type: types.startUploadingOk,
    payload: file
})

export const activarInasistencia = (inasistencia) => ({
    type : types.activarInasistencia,
    payload: inasistencia
})