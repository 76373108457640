import { types } from "../types/types";

export const openCursos = () => ({ type: types.openCursos });
export const openEstudiantes = () => ({ type: types.openEstudiantes });
export const openInasistencias = () => ({ type: types.openInasistencias });
export const openFichaInasistencia = () => ({ type: types.openFichaInasistencia});
export const openFormActualizarCurso = (e) => ({ 
    type: types.openFormActualizarCurso ,
    payload: e
});

export const openActualizarEstudiante = () => ({ type: types.openActualizarEstudiante });

export const openFormCrearEstudiante = () => ({ type: types.openFormCrearEstudiante });
export const openFormCrearApoderado = () => ({ type: types.openFormCrearApoderado });
export const openFormCrearInasistencia = () => ({ type: types.openFormCrearInasistencia });