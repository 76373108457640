import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";


export const crearCurso = (curso) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken('crearCurso', curso, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                dispatch(crearCursoOk(body.result.payload));
            }
        } catch (error) {
            console.log(error)
            dispatch(crearCursoError())
        }
    }
}

const crearCursoOk = (curso) => ({
    type: types.crearCursoOk,
    payload: curso
})

const crearCursoError = () => ({ type: types.crearCursoError });

export const obtenerCursos = () => {
    return async ( dispatch ) => {
        try {
            const resp =  await fetchConToken('obtenerCursos')
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                dispatch(obtenerCursosOk(body.result.payload));
            }else{
                dispatch(obtenerCursosError());
            }
        } catch (error) {
            console.log(error);
            dispatch(obtenerCursosError());
        }
    }
}

const obtenerCursosOk = (cursos) => ({
    type: types.obtenerCursosOk,
    payload: cursos
});

const obtenerCursosError = () => ({ type: types.obtenerCursosError });

export const actualizarCurso = (curso) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`actualizarCurso/${curso._id}`, curso , 'PUT');    
            const body = await resp.json();
            if(body.code === 'undefined.0200') {
                dispatch(actualizarCursoOk(body.result.payload));
            } else {
                dispatch(actualizarCursoError())
            }
        } catch (error) {
            console.log(error);
            dispatch(actualizarCursoError());
        }
    }
}

const actualizarCursoOk = (curso) => ({
    type: types.actualizarCursoOk,
    payload: curso
});

const actualizarCursoError = () => ({ type: types.actualizarCursoError });

export const eliminarCurso = (curso) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`eliminarCurso/${curso._id}`, curso, 'DELETE');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                dispatch(eliminarCursoOk(curso));
                Swal.fire({
                    icon: 'success',
                    title: `${body.message}`,
                    showConfirmButton: false,
                    timer: 1000
                })
            }
            
        } catch (error) {
            console.log(error)
            dispatch(eliminarCursoError)
        }
    }
}

const eliminarCursoOk = (curso) => ({ 
    type: types.eliminarCursoOk,
    payload: curso
});

const eliminarCursoError = () => ({ type : types.eliminarCursoError });




export const activarCurso = (curso) => ({
    type: types.activarCurso,
    payload: curso
})