import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import { openFormCrearApoderado } from "./admin";




export const crearEstudiante = ( estudiante ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken('crearEstudiante', estudiante, 'POST');
            const body = await resp.json();
            if(body.code ==='MNS.RMS.0200'){
                dispatch(crearEstudianteOk(body.result.payload));
                dispatch(activarEstudiante(body.result.payload));
                dispatch(openFormCrearApoderado())
            }
        } catch (error) {
            console.log(error);
            dispatch(crearEstudianteError());
        }
    }
}

const crearEstudianteOk = (estudiante) => ({
    type: types.crearEstudianteOk,
    payload: estudiante
});

const crearEstudianteError = () => ({ type: types.crearEstudianteError });

export const obtenerEstudiantes = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('obtenerEstudiantes');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                dispatch(obtenerEstudiantesOk(body.result.payload));
            }else{
                dispatch(obtenerEstudiantesError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerEstudiantesError());
        }
    }
}

const obtenerEstudiantesOk = (estudiantes) => ({ 
    type: types.obtenerEstudiantesOk ,
    payload: estudiantes
});

const obtenerEstudiantesError = () => ({ type: types.obtenerEstudiantesError })

export const actualizarEstudiante = (estudiante) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`actualizarEstudiante/${estudiante._id}`, estudiante, 'PUT');
            const body = await resp.json();
            console.log(body)
            if(body.code === 'undefined.0200'){
                dispatch(actualizarEstudianteOk(body.result.payload));
                Swal.fire({
                    icon: 'success',
                    title: 'Estudiante actualizado',
                    showConfirmButton: false,
                    timer: 1000
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: `${body.mensaje}`,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarEstudianteError())
        }
    }
}

const actualizarEstudianteOk = (estudiante) => ({ 
    type: types.actualizarEstudianteOk,
    payload: estudiante
});

const actualizarEstudianteError = () => ({ type : types.actualizarEstudianteError });

export const eliminarEstudiante = (estudiante) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`eliminarEstudiante/${estudiante._id}`, estudiante, 'DELETE');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                dispatch(eliminarEstudianteOk(estudiante));
                Swal.fire({
                    icon: 'success',
                    title: `${body.message}`,
                    showConfirmButton: false,
                    timer: 1000
                })
            }
            
        } catch (error) {
            console.log(error)
            dispatch(eliminarEstudianteError)
        }
    }
}

const eliminarEstudianteOk = (estudiante) => ({ 
    type: types.eliminarEstudianteOk,
    payload: estudiante
});

const eliminarEstudianteError = () => ({ type : types.eliminarEstudianteError });

export const activarEstudiante = (estudiante) => ({
    type : types.activarEstudiante,
    payload: estudiante
})