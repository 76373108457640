import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import { openFormCrearInasistencia } from "./admin";



export const crearApoderado = ( apoderado ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken('crearApoderado', apoderado, 'POST');
            const body = await resp.json();
            if(body.code ==='MNS.RMS.0200'){
                dispatch(crearApoderadoOk(body.result.payload));
                dispatch(activarApoderado(body.result.payload))
                dispatch(openFormCrearInasistencia())
            }
        } catch (error) {
            console.log(error);
            dispatch(crearApoderadoError());
        }
    }
}

const crearApoderadoOk = (apoderado) => ({
    type: types.crearApoderadoOk,
    payload: apoderado
});

const crearApoderadoError = () => ({ type: types.crearApoderadoError });

export const obtenerApoderados = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('obtenerApoderados');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                dispatch(obtenerApoderadosOk(body.result.payload));
            }else{
                dispatch(obtenerApoderadosError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerApoderadosError());
        }
    }
}

const obtenerApoderadosOk = (apoderados) => ({ 
    type: types.obtenerApoderadosOk ,
    payload: apoderados
});

const obtenerApoderadosError = () => ({ type: types.obtenerApoderadosError })

export const actualizarApoderado = (apoderado) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`actualizarApoderado/${apoderado._id}`, apoderado, 'PUT');
            const body = await resp.json();
            if(body.ok){
                dispatch(actualizarApoderadoOk(body.apoderado));
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarApoderadoError())
        }
    }
}

const actualizarApoderadoOk = (apoderado) => ({ 
    type: types.actualizarApoderadoOk,
    payload: apoderado
});

const actualizarApoderadoError = () => ({ type : types.actualizarApoderadoError });

export const activarApoderado = (apoderado) => ({
    type: types.activarApoderado,
    payload: apoderado
})